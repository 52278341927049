import styled from "styled-components/macro"
import { layout, pxToRem } from "../../../utils/style-utils"

const PageLayout = styled.main`
  margin: 0 auto;
  max-width: ${pxToRem(layout.pageContentMaxW)};
  max-width: var(--page-max-w);
  width: 100%;
  padding: ${pxToRem(16)} ${pxToRem(16)} 0;
  padding: var(--grid-gap) var(--grid-margin) 0;
`
export default PageLayout
