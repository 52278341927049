import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  icon?: boolean
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(({ color = "currentColor", icon = false, className, ...props }, ref) => {
  const classNames = ["error"] as string[]
  if (className) {
    classNames.push(className)
  }
  if (icon) {
    classNames.push("is-icon--true")
  }
  return (
    <SVG viewBox="0 0 106 106" className={classNames.join(" ")} {...props} ref={ref}>
      <g transform="translate(3 3)" fill="none" fillRule="evenodd">
        <rect fill={color} x="46" y="18.5" width="8" height="38" />
        <rect fill={color} x="46" y="69.5" width="8" height="13" />
        <circle stroke={color} strokeWidth="5" cx="50" cy="50" r="50" />
      </g>
    </SVG>
  )
})

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
