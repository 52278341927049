import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { PoolSeasonStandingsQuery, PoolSeasonStandingsQueryVariables } from "../../../../__generated__/PoolSeasonStandingsQuery"
import { SeasonEnumType } from "../../../../common/enums"
import { sortByOrder } from "../../../../common/sorters"
import { entryPicksUrl } from "../../../../common/url-utils"
import ApiRefresher from "../../../Base/ApiRefresher"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import ErrorView from "../../../components/ErrorView"
import PencilSvg from "../../../components/icons/Pencil"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import useControls from "../../../hooks/useControls"
import { buildPollingProps, extractPoolOgProps } from "../../../hooks/usePoolData"
import { edgeToNode, getQueryWithError, toRank } from "../../../utils/misc-utils"
// import {palette} from "../../../utils/style-utils";
import GameOgMeta from "../../GameOgMeta"

import { POOL_SEASON_STANDINGS_QUERY } from "../../queries"
import Pagination from "../components/Pagination"
import { StandardPoolPageContainer } from "../components/PoolPage"
import StandingsLinks from "../components/StandingsLinks"
import {
  StickyTableColumn,
  StickyTableHeader,
  StickyTableTable,
  StickyTableWrapper,
  StickyTableXWrapper,
  TableHeaderRow,
  TableRow,
  TruncateText,
} from "../components/StickyTable"
import {
  StandingsPeriodCol,
  StandingsPeriodColPrefix,
  StandingsPlayerHeaderCol,
  StandingsPlayerNameCol,
  StandingsPlayerRankCol,
  StandingsPtsCol,
  StandingsPtsColHeader,
} from "../styles/PoolStandings.styles"
import { IStandingsProps } from "./AllPicks"
import { emptyObject, emptyArray } from "../../../../common/misc-utils"
import BracketPoolHome from "./MultipleEntriesArea/PoolHome/BracketPoolHome"
import { useHistory, useLocation } from "react-router-dom"
import JoinedToPoolModal from "../components/EntryModals/JoinedToPoolModal"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"

const emptyPoolPoint = {
  poolRank: "-",
  fantasyPoints: "-",
}
const isMyEntry = (myEntryIds: string[], entryId: string) => myEntryIds.indexOf(entryId) > -1

export const periodPointsFor = (periodId, entry, canViewOverallStandings, currPerId) => {
  if (currPerId === periodId && !canViewOverallStandings) {
    return emptyPoolPoint
  }
  const pp = entry.periodPoints.edges.find((edge) => edge.node && edge.node.periodId === periodId)
  return (pp && pp.node) || emptyPoolPoint
}

const PoolStandings: React.FC<IStandingsProps> = (props) => {
  const { forSubPoolId, header, analyticsTitle, subPoolName, ...pageProps } = props
  const { poolData } = props
  const { poolId, detailedEntry, poolDetail, isManagerModeActive, isInComingSoon, detailedPeriod } = poolData
  const { poolScoringMs, canViewOverallStandings, pollingPeriodId, hasStartedEvent } = buildPollingProps(poolData)
  const gameInstanceUid = (poolDetail && poolDetail.gameInstanceUid) || poolData.gameInstanceUid || ""
  // const periodParams = extractPeriodParams(poolData);
  const controls = useControls(props)
  const includedEntryIds = detailedEntry ? [detailedEntry.id] : ([] as never[])
  const ogProps = extractPoolOgProps(poolData)
  const variables = {
    poolId,
    includedEntryIds,
    forSubPoolId,
    first: controls.first,
    after: controls.after,
    gameInstanceUid,
    sortByOrder: "OVERALL_RANK",
  }
  const skip = !(!!variables.poolId && !!variables.gameInstanceUid) || props.poolData.areaHasMultipleEntriesPerUser
  const poolSeasonStandingsQuery = useQuery<PoolSeasonStandingsQuery, PoolSeasonStandingsQueryVariables>(POOL_SEASON_STANDINGS_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip,
  })

  const location = useLocation()
  const history = useHistory()
  const [showModal, setShowModal] = useState<null | string>(null)
  // looks like we may nest this...
  const modalId: string = (history.location.state && history.location.state["modal"] && history.location.state["modal"]["modalId"]) || ""

  const hideModal = () => {
    const { state } = location ?? {}
    if (state && state["modal"]) {
      const newState: any = { ...state }
      delete newState.modal
      history.replace({ ...location, state: newState })
    } else {
      history.replace(location)
    }
    setShowModal(null)
  }

  useEffect(() => {
    if (modalId) {
      if (!showModal && modalId === JoinedToPoolModal.modalKey) {
        setShowModal(modalId)
      }
    }
  }, [modalId, showModal])

  const withError = getQueryWithError([poolSeasonStandingsQuery])
  if (withError) {
    return <ErrorView error={withError.error} refetch={withError.refetch} />
  }

  const joinedPoolModal = (
    <Modal
      modalType="modal"
      padded={false}
      isOpen={!!showModal}
      variant={"white"}
      onBackgroundClick={() => undefined}
      onEscapeKeydown={() => undefined}
    >
      <JoinedToPoolModal onClose={hideModal} />
    </Modal>
  )

  if (props.poolData.areaHasMultipleEntriesPerUser) {
    return (
      <StandardPoolPageContainer {...props}>
        <BracketPoolHome {...props} />
        {joinedPoolModal}
      </StandardPoolPageContainer>
    )
  }

  const pool = poolSeasonStandingsQuery.data?.gameInstance.pool
  const isLoading = !(poolSeasonStandingsQuery && poolSeasonStandingsQuery.networkStatus >= 3)
  const pageInfo = poolSeasonStandingsQuery.data?.gameInstance.pool.entries.pageInfo || emptyObject
  const entries = ((!isLoading && poolSeasonStandingsQuery.data?.gameInstance.pool.entries.edges) || emptyArray).map(edgeToNode)
  const season = pool && pool.season
  const segment = season && season.segments.edges.map(edgeToNode)[0]
  let periods = ((segment && segment.periods.edges.map(edgeToNode)) || []).sort(sortByOrder)
  // if less than 2 periods, just drop
  if (periods.length < 2) {
    periods = [] as never[]
  }
  // console.log("PoolStandings")
  // console.dir(season)
  // console.dir(periods)
  // console.dir(detailedPeriod)
  // console.dir(entries)
  const isPostSeason = !!season && season.season === SeasonEnumType.post
  const isWeekly = !isPostSeason
  const viewingPeriodOrder =
    (detailedPeriod &&
      periods.length &&
      detailedPeriod.order > 1 &&
      !detailedPeriod.tournamentId &&
      detailedPeriod.order - (hasStartedEvent ? 0 : 1)) ||
    undefined
  return (
    <StandardPoolPageContainer
      {...pageProps}
      padded={false}
      className={`is-weekly__${isWeekly} is-post--${isPostSeason} col-count--${periods.length}`}
    >
      <AnalyticScreen feature={"standings"} subfeature={`pickem`} title={`Picks - Pick em - ${props.analyticsTitle || "Standings"}`} />
      <GameOgMeta {...ogProps} />
      {skip ? null : (
        <ApiRefresher refetchAfterMs={poolScoringMs} query={poolSeasonStandingsQuery} networkStatus={poolSeasonStandingsQuery.networkStatus} />
      )}
      <StandingsLinks {...props} />

      {header || <StickyTableHeader>Overall Leaderboard</StickyTableHeader>}
      <StickyTableWrapper>
        {!isLoading ? (
          <>
            <StickyTableColumn>
              {/*<StandingsTablePrimaryHeader>
                <StandingsPlayerRankCol highlight={false}>Rank</StandingsPlayerRankCol>
                <StandingsPlayerNameCol>Player</StandingsPlayerNameCol>
              </StandingsTablePrimaryHeader> */}
              <TableHeaderRow>
                <StandingsPlayerHeaderCol>Players</StandingsPlayerHeaderCol>
              </TableHeaderRow>
              {entries.map((entr) => {
                return (
                  <TableRow key={entr.id} as={Link} to={entryPicksUrl(entr, viewingPeriodOrder)}>
                    <StandingsPlayerRankCol highlight={isMyEntry(includedEntryIds, entr.id)} className="mobile__rank">
                      {toRank(entr.poolRank) || "-"}
                    </StandingsPlayerRankCol>
                    <StandingsPlayerNameCol className="hover__x mobile__name">
                      <TruncateText>{entr.name}</TruncateText>
                    </StandingsPlayerNameCol>
                    {(!isInComingSoon && (
                      <div className="cta">
                        {(isManagerModeActive && <PencilSvg />) || null}
                        <span>{isManagerModeActive ? "Edit" : "View"}</span>
                      </div>
                    )) ||
                      null}
                  </TableRow>
                )
              })}
            </StickyTableColumn>
            <StickyTableXWrapper>
              <StickyTableTable>
                {/*<StandingsTablePrimaryHeader>
                  <StandingsTablePrimaryHeaderWeek>{isWeekly ? "Week" : "Round"}</StandingsTablePrimaryHeaderWeek>
                  <StandingsTablePrimaryHeaderPts>Pts</StandingsTablePrimaryHeaderPts>
                </StandingsTablePrimaryHeader> */}
                <TableHeaderRow>
                  <div className="spacer" />
                  <StandingsPtsColHeader>{periods.length ? (isPostSeason ? `TOT` : `YTD`) : "Points"}</StandingsPtsColHeader>
                  {periods.map((period) => (
                    <StandingsPeriodCol highlight={false} key={`d${period.id}`}>
                      <StandingsPeriodColPrefix>{isWeekly ? "WK" : ""}</StandingsPeriodColPrefix>
                      {isWeekly ? ` ${period.order}` : period.description}
                    </StandingsPeriodCol>
                  ))}
                </TableHeaderRow>
                {entries.map((entr) => {
                  return (
                    <TableRow key={entr.id}>
                      <div className="spacer" />
                      <StandingsPtsCol>{entr.fantasyPoints}</StandingsPtsCol>
                      {periods.map((period) => {
                        const pp = periodPointsFor(period.id, entr, canViewOverallStandings, pollingPeriodId)
                        return (
                          <StandingsPeriodCol highlight={pp.poolRank === 1} key={`${entr.id}-${period.id}`}>
                            {pp.fantasyPoints}
                          </StandingsPeriodCol>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </StickyTableTable>
            </StickyTableXWrapper>
          </>
        ) : (
          <LoadingView />
        )}
      </StickyTableWrapper>
      {!poolData.isChallengePool && (
        <Pagination {...pageInfo} hasPreviousPage={!!controls.after} goToPage={controls.goToPage} isLoading={isLoading} />
      )}
    </StandardPoolPageContainer>
  )
}

export default PoolStandings
