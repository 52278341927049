import * as React from "react"
import Avatar from "../../../components/Avatar"
import Link from "../../../components/Link"
import {
  AuthGateViewCta,
  AuthGateViewCtas,
  AuthGateViewHr,
  AuthGateViewSubText,
  AuthGateViewText,
  CtaModuleContainer,
} from "../styles/SplashViews.styles"
import AuthGateViewLogo from "./AuthGateViewLogo"

const CtaModule = (props: any) => {
  const { buttons, logo, avatar, text, subText, icon, ...rest } = props
  const hasHr = !!(text || subText)
  return (
    <CtaModuleContainer {...rest}>
      {(avatar && <Avatar {...avatar} />) || null}
      {(logo && <AuthGateViewLogo {...logo} />) || null}
      {icon || null}
      {(text && <AuthGateViewText>{text}</AuthGateViewText>) || null}
      {(subText && <AuthGateViewSubText>{subText}</AuthGateViewSubText>) || null}
      {hasHr && <AuthGateViewHr />}
      <AuthGateViewCtas>
        {buttons.map((button, i) => (
          <AuthGateViewCta as={Link} key={i} {...button} />
        ))}
      </AuthGateViewCtas>
    </CtaModuleContainer>
  )
}

export default CtaModule
