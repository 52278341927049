import styled from "styled-components/macro"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"
import { IHighlightable, leaderboardBreakpointW2, leaderboardBreakpointWpx, stickyTableColumnH } from "../components/StickyTable"

export const StandingsTablePrimaryHeader = styled(FlexRow)`
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(11)};
  background-color: rgb(24, 68, 128);
  height: ${pxToRem(30)};
  width: 100%;
  & > * {
    color: #fff !important;
  }
  @media (max-width: ${leaderboardBreakpointWpx}) {
    display: none;
  }
`
export const StandingsPlayerNameCol = styled(FlexColGrow)`
  font-weight: ${fontWeight.semiBold};
`
export const StandingsPlayerRankCol = styled(FlexCol)<IHighlightable>`
  height: 100%;
  color: #000;
  font-weight: ${fontWeight.bold};
  min-width: ${pxToRem(90)};
  padding-right: ${pxToRem(4)};
  margin-right: ${pxToRem(22)};
  justify-content: center;
  border-left: ${pxToRem(4)} solid ${({ highlight }) => (highlight ? `${palette.uiNavBlue1}` : `rgba(0,0,0,0)`)};
  @media (max-width: ${leaderboardBreakpointWpx}) {
    justify-content: flex-end;
    min-width: ${pxToRem(50)};
    margin-right: ${pxToRem(8)};
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.regular};
  }
  @media (max-width: ${pxToRem(leaderboardBreakpointW2)}) {
    min-width: ${pxToRem(40)};
    font-size: ${pxToRem(11)};
    margin-right: ${pxToRem(6)};
  }
`
export const StandingsPlayerHeaderCol = styled(FlexCol)`
  font-weight: ${fontWeight.bold};
  min-height: ${pxToRem(30)};
  min-width: 100%;
`
export const StandingsColBase = styled(FlexColGrow)`
  justify-content: center;
  color: #6b6b6b;
`
export const StandingsPeriodCol = styled(StandingsColBase)<IHighlightable>`
  min-width: ${pxToRem(45)};
  min-height: ${pxToRem(30)};
  .is-weekly__false & {
    min-width: ${pxToRem(90)};
  }
  color: ${({ highlight }) => (highlight ? `green` : "inherit")};
`
export const StandingsPtsCol = styled(StandingsColBase)`
  color: inherit;
  min-width: ${pxToRem(stickyTableColumnH * 1.5)};
  font-weight: ${fontWeight.bold};
  @media (max-width: ${leaderboardBreakpointWpx}) {
    order: -1;
    color: #000;
  }
`
export const StandingsPtsColHeader = styled(StandingsPtsCol)``
export const StandingsPeriodColPrefix = styled.span`
  padding-right: ${pxToRem(1.5)};
  @media (min-width: ${leaderboardBreakpointWpx}) {
    display: none;
  }
`

export const StandingsTablePrimaryHeaderWeek = styled(FlexColGrow)`
  justify-content: center;
`
export const StandingsTablePrimaryHeaderPts = styled(StandingsPtsCol)`
  flex: 0 1 auto;
`
