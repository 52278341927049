import { useMutation, useQuery } from "@apollo/client"
import React from "react"
import { SubPoolDetailsQuery, SubPoolDetailsQueryVariables } from "../../../../__generated__/SubPoolDetailsQuery"
import { ToggleSubPoolMembershipMutation, ToggleSubPoolMembershipMutationVariables } from "../../../../__generated__/ToggleSubPoolMembershipMutation"
import { DefaultPoolSettings } from "../../../../common/pool-settings"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import ApiRefresher from "../../../Base/ApiRefresher"
import { useLocalKey } from "../../../Base/LocalKey"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonSmallBase, IconButton } from "../../../components/Button"
import ErrorView from "../../../components/ErrorView"
import { ApiFormErrors } from "../../../components/Form"
import EditFa from "../../../components/icons/edit-fa"
import ErrorSvg from "../../../components/icons/Error"
import ExitSvg from "../../../components/icons/Exit"
import GroupsSvg from "../../../components/icons/Groups"
import Link from "../../../components/Link"
import { Logo } from "../../../components/Logos"
import Modal, { ModalWrapper } from "../../../components/Modal"
import Spinner from "../../../components/Spinner"
import useControls from "../../../hooks/useControls"
import { buildPollingProps, extractPoolOgProps, getAuthGateProps } from "../../../hooks/usePoolData"
import PageLayout from "../../../shared/atoms/Page/PageLayout"
import { getQueryWithError, toNodes, toRank } from "../../../utils/misc-utils"
import { palette, pxToRem } from "../../../utils/style-utils"
import { getAuthSearch, getInvitationInfo, liveStandingsRegex } from "../../../utils/url-utils"
import GameOgMeta from "../../GameOgMeta"

import { AuthModal } from "../../NonPoolPages/components/AuthGateView"
// import AuthGateViewLogo from "../../NonPoolPages/components/AuthGateViewLogo";
import CtaModule from "../../NonPoolPages/components/CtaModule"
// import PencilSvg from "../../../components/icons/Pencil";
// import PersonAdd from "../../../components/icons/PersonAdd";
import { ToggleContainer, ToggleContainerButton } from "../../PoolPages/styles/PoolMyEntry.styles"
import { EntryNameModal } from "../../PoolSetupPages/components/CreateEntryForm"
import { FormCtaIcon } from "../../PoolSetupPages/styles/CommonPoolSetup.styles"
import { SUB_POOL_DETAILS_QUERY, TOGGLE_SUB_POOL_MEMBERSHIP_MUTATION } from "../../queries"
import {
  Col,
  StandingsPlayerNameCol,
  StickyTableHeader,
  StickyTableTable,
  TableHeaderRow,
  TableRow,
  TruncateText,
  // StickyTableWrapper, StickyTableXWrapper, StickyTableColumn,
} from "../components/StickyTable"
import PoolAllPicks from "./AllPicks"
import PoolInviteCenter from "./PoolInviteCenter"
import PoolStandings from "./PoolStandings"
import Analytics from "../../../utils/analytics"

// interface IProps extends RouteComponentProps {
//   entryId?: string | null;
//   entry?: any;
//   subPoolDetailsQuery?: any;
//   poolDetail: any;
//   first: number;
//   after: string;
//   poolName: string;
//   subPoolId?: string;
//   poolUrl: string;
//   upsertEntryMutation: UpsertEntryMutation_upsertEntry;
//   toggleSubPoolMembershipMutation: ToggleSubPoolMembershipMutation_toggleSubPoolMembership;
//   toggleSubPoolMembershipMutationState?: any;
//   needsAuthSearch: string | null;
//   openModal: (name: string | null) => void;
//   openModalKey?: string | null;
//   periodId: string;
//   gameInstanceUid: string;
//   isInComingSoon: boolean;
//   hasAutogeneratedName: boolean;
//   liveEventMs: number;
//   poolScoringMs: number;
//   defaultMs: number;
// }

const headerStyles = { color: "#000", fontWeight: 800 }
// const logoStyles = {
//   height: "25vh",
// };

const upsertSubPoolModalKey = "UpsertPoolModal"
const modalKeyDivider = "-"
const modalKeyIsOpenFlag = "open"
const modalKeyIsClosedFlag = "closed"
const inviteModalKey = "InviteSubPoolModal"
const leaveModalKey = "LeaveModal"
const preventDefaultOnEvt = (evt?: any) => {
  if (evt) {
    evt.preventDefault()
  }
}
export const getSubPoolModalValeus = (openModalKey?: string | null) => {
  const parts = (openModalKey || "").split(modalKeyDivider)
  return {
    isOpen: parts.length > 1 ? parts[1] === modalKeyIsOpenFlag : false,
    modalKey: parts.length > 0 ? parts[0] : null,
    subPoolId: parts.length > 2 ? parts[2] : null,
  }
}
// const creationModalMatch = new RegExp([upsertSubPoolModalKey, inviteModalKey].join("|"));
const ctaStyles = {
  minHeight: "50vh",
  color: palette.gray1,
  width: "100%",
  maxWidth: pxToRem(400),
}
const poolUpsertNavStyles = {
  width: pxToRem(400),
  maxWidth: "80%",
  margin: `${pxToRem(0)} auto`,
}
const actionBtnStyles = {
  fontSize: "0.8em",
}

const tableStyles = {
  minWidth: "unset",
  borderLeft: `1px solid ${palette.poolPageBorder}`,
}
const tableHeaderStyles = {
  marginLeft: 0,
  marginRight: 0,
  borderLeft: "none",
  borderRight: "none",
}
class SubPoolRow extends React.PureComponent<any> {
  public toggleSubPoolModal = (evt: any) => {
    preventDefaultOnEvt(evt)
    const { subPoolEntry, toggleSubPoolModal, leaveGroupModal } = this.props
    if (subPoolEntry.subPool.isCreator) {
      return toggleSubPoolModal(subPoolEntry.subPool.id)
    } else {
      return leaveGroupModal(subPoolEntry.subPool.id)
    }
  }

  public render() {
    const { subPoolEntry } = this.props
    const isCreator = subPoolEntry.subPool.isCreator
    const Icon = isCreator ? EditFa : ExitSvg
    return (
      <TableRow>
        <Col as={IconButton} onClick={this.toggleSubPoolModal} style={actionBtnStyles}>
          <Icon />
          {!isCreator ? <span>Leave</span> : <span>Manage</span>}
        </Col>
        <StandingsPlayerNameCol className="padded" as={Link} to={subPoolEntry.subPool.url}>
          <TruncateText className="hover__x">{subPoolEntry.subPool.name}</TruncateText>
        </StandingsPlayerNameCol>
        <Col>{toRank(subPoolEntry.poolRank) || "-"}</Col>
        <Col>{subPoolEntry.subPool.entriesCount}</Col>
      </TableRow>
    )
  }
}

class PoolSubPool extends React.Component<any> {
  public createGroupClick = (event) => {
    const { entry, needsAuthSearch, openModal, hasAutogeneratedName } = this.props
    preventDefaultOnEvt(event)
    Analytics.trackAction("create group", "groups", "create_a_group")
    if (needsAuthSearch) {
      openModal(AuthModal.modalKey)
    } else if (!entry || hasAutogeneratedName) {
      openModal(EntryNameModal.modalKey)
    } else {
      this.openUpsertGroupModal()
      // openModal(upsertSubPoolModalKey);
    }
  }

  public joinClick = (event) => {
    const {
      entry,
      needsAuthSearch,
      openModal,
      hasAutogeneratedName,
      toggleSubPoolMembershipMutation,
      joinKey,
      toggleSubPoolMembershipMutationState,
      periodId,
      invitationId,
      poolUrl,
      history,
      poolPeriodQuery,
    } = this.props
    const subPoolId = this.props.subPoolId || this.getModalValues().subPoolId
    // console.dir(event)
    preventDefaultOnEvt(event)
    if (needsAuthSearch) {
      openModal(AuthModal.modalKey)
    } else if (!entry || hasAutogeneratedName) {
      openModal(EntryNameModal.modalKey)
    } else if (!toggleSubPoolMembershipMutationState || !toggleSubPoolMembershipMutationState.loading) {
      const subPoolEntries = toNodes(entry?.subPoolEntries) as any[]
      const poolEntry = subPoolEntries.find((spe) => spe.subPool.id === subPoolId)
      const variables = {
        joinKey,
        subPoolId,
        entryId: entry.id,
        periodId,
        invitationId,
      }
      toggleSubPoolMembershipMutation({ variables }).then(() => {
        if (poolEntry) {
          // pool leave success
          this.closeModal()
          // const url = withoutDomain(poolUrl) + "/groups";
          // this.props.openModal(null);
          // setTimeout(() => this.props.history.push(url), 800);
        } else {
          // Pool join success
          if (entry.needsToMakePicks && poolPeriodQuery.data?.gameInstance.period.events.length) {
            // if no picks, go to picks page
            history.push(withoutDomain(poolUrl))
          }
          // otherwise, stay on this page (leaderboard)
        }
      })
    }
  }

  public closeModal = (event?) => {
    preventDefaultOnEvt(event)
    const vals = this.getModalValues()
    if (vals.modalKey) {
      this.props.openModal(this.getModalKeyFor(vals.subPoolId || "", vals.modalKey, false))
    } else {
      this.onModalClosed()
    }
  }

  public getModalValues() {
    return getSubPoolModalValeus(this.props.openModalKey)
  }

  public onModalClosed = () => {
    this.props.openModal(null)
  }

  public handleUpsertFinish = (subPool?: any) => {
    const vals = this.getModalValues()
    if (vals.subPoolId) {
      this.closeModal()
      // this.props.openModal(this.getModalKeyFor(subPool.id, inviteModalKey));
    } else {
      // this.closeModal()
      this.props.openModal(this.getModalKeyFor(subPool.id, inviteModalKey))
    }
    // if (subPool && subPool.url) {
    //   // const url = withoutDomain(subPool.url); //  + `?modal=${inviteModalKeyHack}`
    //   // this.props.openModal(null);
    //   // setTimeout(() => this.props.history.push(url), 800);
    // }
  }

  public openInviteModal = (event) => {
    preventDefaultOnEvt(event)
    const subPoolId = this.props.subPoolId || this.getModalValues().subPoolId
    if (subPoolId) {
      this.props.openModal(this.getModalKeyFor(subPoolId, inviteModalKey))
    }
  }
  public openUpsertGroupModal = (event?) => {
    preventDefaultOnEvt(event)
    const subPoolId = this.props.subPoolId || this.getModalValues().subPoolId
    if (subPoolId) {
      this.props.openModal(this.getModalKeyFor(subPoolId, upsertSubPoolModalKey))
    } else {
      this.props.openModal(this.getModalKeyFor("", upsertSubPoolModalKey))
    }
  }
  public leaveGroupModal = (subPoolId: string) => {
    this.props.openModal(this.getModalKeyFor(subPoolId, leaveModalKey))
  }
  public getModalKeyFor(subPoolId: string, modalKey: string, isOpen = true) {
    return [modalKey, isOpen ? modalKeyIsOpenFlag : modalKeyIsClosedFlag, subPoolId].join(modalKeyDivider)
  }

  public toggleSubPoolModal = (subPoolId: string) => {
    // NOTE qac: we default to invite modal
    this.props.openModal(this.getModalKeyFor(subPoolId, inviteModalKey))
    // this.props.openModal(upsertSubPoolModalKey);
    // const {openModalKey} = this.props
  }

  // prev: any = null

  public render() {
    const {
      poolName,
      entry,
      needsAuthSearch,
      poolDetail,
      toggleSubPoolMembershipMutationState,
      openModalKey,
      upsertEntryMutation,
      periodId,
      hasAutogeneratedName,
      subPoolDetailsQuery,
      joinKey,
      poolUrl,
      gameInstanceUid,
      seasonType,
      isCbsAppWebview,
      returnUrl,
      defaultMs,
      location,
      history,
      match,
      poolData,
    } = this.props
    const routeProps = { location, history, match, poolData }
    const onSubPoolPage = !!this.props.subPoolId
    const modalValues = this.getModalValues()
    const subPoolId = this.props.subPoolId || modalValues.subPoolId
    // console.dir(poolDetail)
    // console.dir(toggleSubPoolMembershipMutationState)
    // console.debug(`openModalKey: ${openModalKey}`)
    // console.log(`openModal: ${openModal === this.prev}`)
    // this.prev = openModal
    const subPoolEntries = toNodes(entry?.subPoolEntries)
    const poolEntry = subPoolEntries.find((spe) => (spe as any).subPool.id === subPoolId)
    const subPoolDetail = subPoolDetailsQuery.data?.subPool || (poolEntry && (poolEntry as any).subPool) || null
    const invitedViaEntry = subPoolDetailsQuery.data?.entry
    const season = poolDetail && poolDetail.season
    const poolId = poolDetail && poolDetail.id
    const entryId = (entry && entry.id) || null
    const subPoolName = (subPoolDetail && subPoolDetail.name) || ""
    const needsToNameEntry = !entryId || hasAutogeneratedName
    const isTogglingMembership = !!(toggleSubPoolMembershipMutationState && toggleSubPoolMembershipMutationState.loading)
    const inviteModalOpen = modalValues.modalKey === inviteModalKey // (openModalKey || "").includes(inviteModalKey)
    const upsertModalOpen = modalValues.modalKey === upsertSubPoolModalKey // (openModalKey || "").includes(upsertSubPoolModalKey)
    const creationModalOpen = (inviteModalOpen || upsertModalOpen) && modalValues.isOpen
    // const modalHeader = creationModalOpen ? inviteModalOpen && `Invite Buddies to: ${subPoolName}` || `${subPoolId ? "Update Group Name" : "Create a buddy group"}` : null
    const modalHeader = subPoolId ? `Manage Buddy Group` : `Create a Buddy Group`
    const defaultModalProps = {
      onBackgroundClick: this.closeModal,
      afterClose: this.onModalClosed,
    }
    const CreationModal =
      (entryId && (
        <Modal isOpen={creationModalOpen} afterClose={this.onModalClosed} onBackgroundClick={preventDefaultOnEvt}>
          <ModalWrapper header={modalHeader} closeBtnAction={this.closeModal} padded={true}>
            {subPoolId ? (
              <ToggleContainer style={poolUpsertNavStyles}>
                <ToggleContainerButton as="button" type="button" isActive={upsertModalOpen} onClick={this.openUpsertGroupModal}>
                  Edit Name
                </ToggleContainerButton>
                <ToggleContainerButton as="button" type="button" isActive={inviteModalOpen} onClick={this.openInviteModal}>
                  Invite
                </ToggleContainerButton>
              </ToggleContainer>
            ) : null}
            {inviteModalOpen && (
              <PoolInviteCenter
                subPoolId={subPoolId || (openModalKey || "").toString().split("-")[0]}
                joinKey={(subPoolDetail && subPoolDetail.joinKey) || undefined}
                poolUrl={(subPoolDetail && subPoolDetail.url) || poolUrl}
                isModal={true}
                emailOnly={false}
                {...routeProps}
              />
            )}
          </ModalWrapper>
        </Modal>
      )) ||
      null
    if (onSubPoolPage && poolEntry) {
      const allPicksProps = DefaultPoolSettings

      const header = (
        <StickyTableHeader>
          <span>Group:</span>&nbsp;&nbsp;<strong style={headerStyles}>{subPoolName}</strong>
        </StickyTableHeader>
      )
      if (liveStandingsRegex.test(location.search)) {
        return <PoolAllPicks {...allPicksProps} analyticsTitle={`Group Live Standings`} header={header} forSubPoolId={subPoolId} {...routeProps} />
      } else {
        return <PoolStandings {...allPicksProps} analyticsTitle={`Group Standings`} header={header} forSubPoolId={subPoolId} {...routeProps} />
      }
    } else {
      let text: React.ReactNode =
        (invitedViaEntry && (
          <>
            <strong>{invitedViaEntry.name}</strong>&nbsp;<span>has invited you to join their Buddy Group!</span>
          </>
        )) ||
        `Welcome to this ${poolName} Group!`
      const avatar = null // invitedViaEntry && {src: invitedViaEntry.avatarUrl, alt: `Invited by ${invitedViaEntry.name}`} || null;
      let subText: React.ReactNode = ""
      const ctaButtons = [] as any[]
      let icon: React.ReactNode = joinKey ? (
        <Logo season={season} gameInstanceUid={gameInstanceUid} />
      ) : (
        <FormCtaIcon>
          <GroupsSvg />
        </FormCtaIcon>
      )
      if (onSubPoolPage) {
        if (!joinKey) {
          icon = <ErrorSvg />
          ctaButtons.push({
            to: `${poolUrl}/groups`,
            children: `View your groups`,
          })
          subText = `You must be invited to join this group.  Please ask the group creator for an invite.`
        } else if (needsAuthSearch) {
          ctaButtons.push({
            to: `/auth/login?${needsAuthSearch}`,
            children: `Login / Register`,
          })
          subText = `You must log in or register to join`
        } else if (needsToNameEntry) {
          ctaButtons.push({
            onClick: this.joinClick,
            to: "#",
            children: `Create Entry`,
          })
          subText = `Create your Game Entry to finish joining!`
        } else {
          ctaButtons.push({
            onClick: this.joinClick,
            to: "#",
            children: `Join Group`,
          })
          subText = ``
        }
        if (toggleSubPoolMembershipMutationState && toggleSubPoolMembershipMutationState.loading && ctaButtons.length) {
          ctaButtons[0].children = <Spinner size={32} />
          ctaButtons[0].disabled = true
        }
      } else if (needsToNameEntry) {
        ctaButtons.push({
          onClick: this.createGroupClick,
          to: "#",
          children: `Name Your Entry`,
        })
        text = `Welcome to Buddy Groups`
        subText = `First name your Entry and then create your first group!`
      } else {
        ctaButtons.push({
          onClick: this.createGroupClick,
          to: "#",
          children: `Create a Group`,
        })
        if (subPoolEntries.length) {
          text = ``
          icon = null
        } else {
          text = `Create a Buddy Group`
          subText = `Compete against your friends by starting a buddy group now. It’s simple, just tap the button below, name your group and send invites. `
        }
      }
      return (
        <PageLayout>
          <ApiRefresher refetchAfterMs={defaultMs} query={subPoolDetailsQuery} networkStatus={subPoolDetailsQuery.networkStatus} />
          <AnalyticScreen
            feature={`groups`}
            subfeature={onSubPoolPage ? "landing" : "listing"}
            title={`Picks - Pick em - ${onSubPoolPage ? "Group Landing" : "Groups Listing"}`}
          />
          {onSubPoolPage && (
            <GameOgMeta
              gameInstanceUid={gameInstanceUid}
              seasonType={seasonType}
              year={season && season.year}
              poolName={poolName || (poolDetail && poolDetail.name)}
              subPoolName={subPoolName}
            />
          )}
          {(onSubPoolPage && (
            <CtaModule style={ctaStyles} icon={(!avatar && icon) || null} text={text} avatar={avatar} subText={subText} buttons={ctaButtons} />
          )) || (
            <>
              {(subPoolEntries.length && (
                <StickyTableTable style={tableStyles}>
                  <StickyTableHeader style={tableHeaderStyles}>Your Groups</StickyTableHeader>
                  <TableHeaderRow>
                    <Col />
                    <StandingsPlayerNameCol className="padded">Name</StandingsPlayerNameCol>
                    <Col>Your Rank</Col>
                    <Col>Members</Col>
                  </TableHeaderRow>
                  {subPoolEntries.map((subPoolEntry) => {
                    return (
                      <SubPoolRow
                        key={(subPoolEntry as any).id}
                        subPoolEntry={subPoolEntry}
                        toggleSubPoolModal={this.toggleSubPoolModal}
                        leaveGroupModal={this.leaveGroupModal}
                      />
                    )
                  })}
                </StickyTableTable>
              )) ||
                null}
              <CtaModule style={icon && ctaStyles} icon={icon} text={text} subText={subText} buttons={ctaButtons} />
            </>
          )}
          <Modal isOpen={modalValues.modalKey === leaveModalKey && modalValues.isOpen} modalType="dialog" {...defaultModalProps}>
            <AnalyticScreen subfeature={`leave pool`} title={`Leave Pool`} isModal={true} />
            <ModalWrapper header="Confirm Action" isDialog={true} closeBtnAction={this.closeModal}>
              <p>Are you sure you want to leave this pool?</p>
              <ButtonSmallBase disabled={isTogglingMembership} onClick={this.joinClick}>
                {isTogglingMembership ? <Spinner /> : `Yes`}
              </ButtonSmallBase>
            </ModalWrapper>
          </Modal>
          <AuthModal
            isOpen={openModalKey === AuthModal.modalKey}
            close={this.closeModal}
            productAbbrev={season && season.productAbbrev}
            masterProductId={season && season.masterProductId}
            gameInstanceUid={gameInstanceUid}
            isCbsAppWebview={isCbsAppWebview}
            season={season}
            returnUrl={returnUrl}
            textPrompt={`Create your account to ${subPoolId ? `compete in this group` : `create a group`}`}
          />
          <EntryNameModal
            isOpen={openModalKey === EntryNameModal.modalKey}
            mutation={upsertEntryMutation}
            entryId={entryId}
            periodId={periodId}
            poolId={poolId}
            gameInstanceUid={gameInstanceUid}
            onFinish={this.closeModal}
            season={season}
          />
          {CreationModal}
          {toggleSubPoolMembershipMutationState && <ApiFormErrors error={toggleSubPoolMembershipMutationState.error} />}
        </PageLayout>
      )
    }
  }
}

function PoolSubPoolWrapper(props: TPoolRouteProps) {
  const { location, match, poolData } = props
  const { params } = match
  const controls = useControls(props)
  const { gameInstanceUid, hasAutogeneratedName, periodId, poolDetail, detailedEntry, hasCbsAccount, productSeason, upsertEntryMutation } = poolData
  const subPoolId = params.subPoolId || getSubPoolModalValeus().subPoolId
  // console.dir(match)
  const [toggleSubPoolMembershipMutation, toggleSubPoolMembershipMutationState] = useMutation<
    ToggleSubPoolMembershipMutation,
    ToggleSubPoolMembershipMutationVariables
  >(TOGGLE_SUB_POOL_MEMBERSHIP_MUTATION)
  const skip = !subPoolId
  const invitedByInfo = getInvitationInfo(location.search)
  const variables = {
    id: subPoolId || "",
    entryId: (invitedByInfo.invitedByEntryId && invitedByInfo.invitedByEntryId.toString()) || "",
    skipInviter: !invitedByInfo.invitedByEntryId,
  }
  const subPoolDetailsQuery = useQuery<SubPoolDetailsQuery, SubPoolDetailsQueryVariables>(SUB_POOL_DETAILS_QUERY, {
    variables,
    skip,
  })
  const modalActions = useLocalKey("modal")
  const withError = getQueryWithError([subPoolDetailsQuery])
  if (withError) {
    return <ErrorView error={withError.error} refetch={withError.refetch} />
  }
  const { defaultMs } = buildPollingProps(poolData)
  const authGateParams = getAuthGateProps(poolData, location)
  const ogProps = extractPoolOgProps(poolData)
  const productYear = (productSeason && productSeason.season) || new Date().getFullYear()
  const needsAuthSearch = hasCbsAccount
    ? null
    : getAuthSearch(authGateParams.returnUrl, authGateParams.productAbbrev, authGateParams.masterProductId, authGateParams.isCbsAppWebview)
  const ttagProps = {
    year: productYear,
    productAbbrev: authGateParams.productAbbrev,
  }
  return (
    <PoolSubPool
      {...ogProps}
      {...ttagProps}
      {...invitedByInfo}
      {...props}
      {...controls}
      {...authGateParams}
      poolData={poolData}
      hasAutogeneratedName={hasAutogeneratedName}
      upsertEntryMutation={upsertEntryMutation}
      toggleSubPoolMembershipMutation={toggleSubPoolMembershipMutation}
      toggleSubPoolMembershipMutationState={toggleSubPoolMembershipMutationState}
      needsAuthSearch={needsAuthSearch}
      poolDetail={poolDetail}
      periodId={periodId}
      gameInstanceUid={gameInstanceUid}
      openModalKey={modalActions.value}
      openModal={modalActions.update}
      subPoolId={params.subPoolId}
      subPoolDetailsQuery={subPoolDetailsQuery}
      entry={detailedEntry}
      defaultMs={defaultMs}
    />
  )
}

export default PoolSubPoolWrapper
