import * as React from "react"

const Svg = ({ color = "currentColor" }) => (
  <svg className="exit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    {/* tslint:disable-next-line */}
    <path
      fill={color}
      d="M215.469 332.802l29.863 29.864L352 256 245.332 149.333l-29.863 29.865 55.469 55.469H64v42.666h205.864l-54.395 55.469zM405.334 64H106.666C83.198 64 64 83.198 64 106.666V192h42.666v-85.333h298.668v298.668H106.666V320H64v85.334C64 428.802 83.198 448 106.666 448h298.668C428.802 448 448 428.802 448 405.334V106.666C448 83.198 428.802 64 405.334 64z"
    />
  </svg>
)

export default Svg
